import styled from "styled-components";

const StyledStoreCard = styled.div`
	@media only screen and (min-width: 2350px) {
		font-size: 22px;
	}

	background: #ffffff;
	width: 100%;
	max-width: 585px;
	height: 215px;
	position: relative;
	border: 1px solid #f4f4f4;
	border-radius: 5px;
	display: flex;
	margin: 50px auto auto;
	clear: both;

	.download-card-image {
		padding-left: 35px;
		margin-top: -50px;
		width: 27.5%;
	}
	.download-card-content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 425px;
		text-align: center;
		padding: 60px 20px 20px 50px;
	}

	.download-link {
		display: inline-block;
		width: 149px;
		height: 45px;
		margin: 0 5px 0 5px;
	}

	@media only screen and (max-width: 1200px) {
		background: none;
		max-width: none;
		width: auto;
		height: auto;
		position: static;
		border: 0;
		padding: 20px;
		box-sizing: border-box;

		.download-card-image {
			display: none;
		}
		.download-card-content {
			display: block;
			width: auto;
			text-align: center;
			padding: 25px;
			box-sizing: border-box;
			margin: auto;
			border: 1px solid #f4f4f4;
			background: #ffffff;
		}
		.download-head {
			position: static;
			width: 100%;
			margin: auto;
			margin-bottom: 10px;
		}
		.download-links {
			position: static;
			width: 100%;
			margin: auto;
			text-align: center;
		}
		.download-link {
			display: block;
			width: 149px;
			height: 45px;
			margin: 5px 0 5px 0;
		}
	}
`;

export default StyledStoreCard;
