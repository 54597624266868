import styled from "styled-components";

const StyledReferenceDetailContent = styled.div`
	padding-bottom: 70px;
	padding-top: 120px;
	.reference-detail-logo,
	.reference-detail-company {
		display: inline-block;
		line-height: 60px;
		vertical-align: middle;
		padding: 0 15px 0 15px;
	}

	.reference-detail-section {
		padding-bottom: 100px;
		& > div > :nth-child(1),
		& > div > :nth-child(2) {
			max-width: 600px;
			margin: auto;
			text-align: center;
		}
		h3 {
			background: #dbd6cc;
			font-size: 14px;
			display: inline-block;
			padding: 5px 10px 5px 10px;
			box-sizing: border-box;
			margin-bottom: 20px;
		}
		.reference-detail.logo {
			text-align: center;
		}
		.reference-detail.content {
			margin-bottom: 20px;
		}
	}

	.body-text > .gatsby-resp-image-wrapper,
	.body-text > .gatsby-resp-image-figure {
		margin-top: 40px;
		margin-bottom: 40px;
		width: 48%;
		clear: both;
		&:nth-of-type(2n) {
			float: left;
			margin-right: 2% !important;
			@media only screen and (max-width: 1200px) {
				float: none;
				max-width: 200px;
				margin-right: auto !important;
			}
		}
		&:nth-of-type(2n-1) {
			float: right;
			margin-left: 2% !important;
			@media only screen and (max-width: 1200px) {
				float: none;
				max-width: 200px;
				margin-left: auto !important;
			}
		}
	}

	@media only screen and (max-width: 1200px) {
		padding-top: 70px;
		.reference-detail-section {
			width: 100%;
			box-sizing: border-box;
			padding: 30px 30px 0px;
			.reference-detail.logo {
				text-align: center;
			}
			.reference-detail.content {
				margin-bottom: 20px;
			}
		}
	}
`;

export default StyledReferenceDetailContent;
