import React from "react";
import { string, shape, array } from "prop-types";
import Img from "gatsby-image";
import ContentRow from "../ContentRow";
import StoreCard from "../StoreCard";
import BodyText from "../Blog/Article/BodyText";
import StyledReferenceDetailContent from "./style";

const ReferenceDetailContent = ({
	logo,
	title,
	html,
	storeImage,
	storeApple,
	storeGoogle,
	storeHeadline,
	color,
	appStoreImage,
	googlePlayImage,
	technologies
}) => {
	return (
		<StyledReferenceDetailContent
			style={{
				backgroundColor: color
			}}
		>
			<ContentRow rowContentClass="no-padding">
				<div className="reference-detail-section">
					<div className="reference-detail logo">
						<div className="reference-detail-logo">
							<Img
								fixed={logo}
								style={{ borderRadius: "7px" }}
								fadeIn={false}
								alt={`${title} - preview`}
							/>
						</div>
						<div className="reference-detail-company">
							<h1>{title}</h1>
						</div>
					</div>
					<BodyText body={html} className="styled-reference" />
				</div>
				{storeImage && storeHeadline && (storeApple || storeGoogle) && (
					<StoreCard
						src={storeImage}
						linkAppStore={storeApple}
						linkGooglePlay={storeGoogle}
						storeHeadline={storeHeadline}
						appStoreImage={appStoreImage}
						googlePlayImage={googlePlayImage}
					/>
				)}
			</ContentRow>
		</StyledReferenceDetailContent>
	);
};

ReferenceDetailContent.propTypes = {
	logo: shape({
		src: string.isRequired,
		srcSet: string.isRequired
	}).isRequired,
	title: string.isRequired,
	html: string.isRequired,
	storeApple: string,
	storeGoogle: string,
	storeHeadline: string,
	storeImage: shape({
		src: string.isRequired,
		srcSet: string.isRequired
	}),
	color: string,
	technologies: array
};

ReferenceDetailContent.defaultProps = {
	color: "#ffffff",
	storeApple: null,
	storeGoogle: null,
	storeHeadline: null,
	storeImage: null,
	technologies: []
};

export default ReferenceDetailContent;
