import React from "react";
import PropTypes from "prop-types";
import StyledStoreCard from "./styles";
import Image from "../Image";

const StoreCard = ({
	src,
	linkAppStore,
	linkGooglePlay,
	storeHeadline,
	appStoreImage,
	googlePlayImage
}) => {
	return (
		<StyledStoreCard>
			<div className="download-card-image">
				{src && (
					<Image data={src} className="image" alt="Synetech - Oriflame app" />
				)}
			</div>
			<div className="download-card-content">
				<div className="download-head">
					<h3>{storeHeadline}</h3>
				</div>
				<div className="download-links">
					{linkAppStore && (
						<a href={linkAppStore} className="download-link" target="blank">
							{appStoreImage ? (
								<Image
									data={appStoreImage}
									alt="Synetech - Oriflame AppStore"
								/>
							) : (
								"App Store"
							)}
						</a>
					)}
					{linkGooglePlay && (
						<a href={linkGooglePlay} className="download-link" target="blank">
							{googlePlayImage ? (
								<Image
									data={googlePlayImage}
									alt="Synetech - Oriflame Google Play"
								/>
							) : (
								"Goole Play"
							)}
						</a>
					)}
				</div>
			</div>
		</StyledStoreCard>
	);
};

StoreCard.propTypes = {
	src: PropTypes.shape({
		src: PropTypes.string.isRequired,
		srcSet: PropTypes.string.isRequired
	}).isRequired,
	appStoreImage: PropTypes.shape({
		src: PropTypes.string.isRequired,
		srcSet: PropTypes.string.isRequired
	}).isRequired,
	googlePlayImage: PropTypes.shape({
		src: PropTypes.string.isRequired,
		srcSet: PropTypes.string.isRequired
	}).isRequired,
	storeHeadline: PropTypes.string.isRequired,
	linkAppStore: PropTypes.string,
	linkGooglePlay: PropTypes.string
};

StoreCard.defaultProps = {
	linkAppStore: null,
	linkGooglePlay: null
};

export default StoreCard;
