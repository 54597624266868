import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import ContentRow from "../components/ContentRow";
import ContactRow from "../components/ContactRow";
import Main from "../components/Main";
import CTA from "../components/CTA";
import ReferenceDetailContent from "../components/Reference/ReferenceDetailContent";

const ReferenceDetail = ({ data, pageContext }) => {
	const {
		title,
		description,
		lang,
		logo,
		color,
		storeApple,
		storeGoogle,
		storeImage,
		storeHeadline
	} = data.reference.frontmatter;
	return (
		<Layout pageContext={pageContext}>
			<SEO title={title} description={description} lang={lang} />

			<Main className="page-container">
				<ReferenceDetailContent
					logo={logo.childImageSharp.fixed}
					title={title}
					html={data.reference.body}
					storeImage={storeImage}
					storeHeadline={storeHeadline}
					storeApple={storeApple}
					storeGoogle={storeGoogle}
					color={color}
					appStoreImage={data.appStoreImage}
					googlePlayImage={data.googlePlayImage}
				/>

				<ContentRow background="green-light" rowContentClass="no-padding">
					<CTA
						headline={pageContext.REFERENCE_DETAIL_CTA_HEADLINE}
						pageContext={pageContext}
					/>
				</ContentRow>
				<ContactRow pageContext={pageContext} />
			</Main>
		</Layout>
	);
};

ReferenceDetail.propTypes = {
	pageContext: PropTypes.objectOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.bool,
			PropTypes.object,
			PropTypes.array
		])
	).isRequired
};

export default ReferenceDetail;

export const referenceDetailQuery = graphql`
	query referenceDetailByURL($url: String!, $lang: String!) {
		appStoreImage: file(relativePath: { eq: "app-store.svg" }) {
			publicURL
			extension
		}
		googlePlayImage: file(relativePath: { eq: "google-play.svg" }) {
			publicURL
			extension
		}
		reference: mdx(frontmatter: { url: { eq: $url }, lang: { eq: $lang } }) {
			body
			frontmatter {
				title
				description
				url
				lang
				logo {
					childImageSharp {
						fixed(width: 60) {
							...GatsbyImageSharpFixed_withWebp_noBase64
						}
					}
				}
				title
				storeImage {
					childImageSharp {
						fixed(width: 125) {
							...GatsbyImageSharpFixed_withWebp_noBase64
						}
					}
				}
				storeApple
				storeGoogle
				storeHeadline
				color
			}
		}
	}
`;
